import styled, { css } from "styled-components";
import {
  TRANSLATE,
  fitString,
  parseCSS,
  parseDataSize,
  parseFileData,
} from "scripts/FunctionsBundle";
import React, { useCallback, useRef, useState } from "react";
import IP from "@utils/ImageProvider";
import Input from "./Input";
import { Person, Study, User } from "@utils/ClassTypes";
import {
  FSAction,
  FSPathProps,
  GS,
  STAction,
  STPathProps,
  createUser,
  isAdmin,
} from "App";
import { arrayUnion } from "firebase/firestore";
import CRUD from "./CRUD";
import {
  PDFDocument,
  PDFRawStream,
  decodePDFRawStream,
  arrayAsString,
  PDFRef,
  PDFObject,
  PDFPage,
} from "pdf-lib";
import { useRevalidator } from "react-router";

// #region ##################################################################################### REMOVE PDF BACKGROUND
// Cheers to @Hopding at https://github.com/Hopding/pdf-lib/issues/446
const whitespace = "[\\0\\t\\n\\f\\r\\ ]+";
const forwardSlash = "\\/";
const alphanumerics = "\\w+";
const decimal = "\\d+\\.\\d+|\\d+";

// prettier-ignore
const rgx = new RegExp(
  `${forwardSlash}${alphanumerics}${whitespace}cs${whitespace}` +
  `1${whitespace}1${whitespace}1${whitespace}sc${whitespace}` +
  `(?<x1>${decimal})${whitespace}(?<y1>${decimal})${whitespace}m${whitespace}` +
  `(?<x2>${decimal})${whitespace}(?<y2>${decimal})${whitespace}l${whitespace}` +
  `(?<x3>${decimal})${whitespace}(?<y3>${decimal})${whitespace}l${whitespace}` +
  `(?<x4>${decimal})${whitespace}(?<y4>${decimal})${whitespace}l${whitespace}` +
  `h${whitespace}` +
  `f`
);

const almostEqual = (a: string | number, b: string | number, error = 0.1) =>
  Math.abs(Number(a) - Number(b)) <= error;

const tryToDecodeStream = (maybeStream?: PDFObject) => {
  if (maybeStream instanceof PDFRawStream) {
    return arrayAsString(decodePDFRawStream(maybeStream).decode());
  }
  return undefined;
};

const removeWhiteBackground = (
  streamContents: string,
  size: { width: number; height: number }
) => {
  let match = streamContents.match(rgx);
  while (match) {
    const { x1, y1, x2, y2, x3, y3, x4, y4 } = match.groups || {};
    const matchSizeIsWithinSize =
      almostEqual(x1, 0) &&
      almostEqual(y1, size.height, 5) &&
      almostEqual(x2, size.width, 5) &&
      almostEqual(y2, size.height, 5) &&
      almostEqual(x3, size.width, 5) &&
      almostEqual(y3, 0) &&
      almostEqual(x4, 0) &&
      almostEqual(y4, 0);
    if (!matchSizeIsWithinSize) break;
    const targetStart = match.index || 0;
    const targetEnd = targetStart + match[0].length;
    streamContents =
      streamContents.substring(0, targetStart) +
      streamContents.substring(targetEnd, streamContents.length);
    match = streamContents.match(rgx);
  }
  return streamContents;
};

const removePageBackground = (page: PDFPage) => {
  const { Contents } = page.node.normalizedEntries();
  if (!Contents) return;
  Contents.asArray().forEach((streamRef) => {
    if (streamRef instanceof PDFRef) {
      const stream = page.doc.context.lookup(streamRef);
      const contents = tryToDecodeStream(stream);
      if (contents) {
        const newContents = removeWhiteBackground(contents, page.getSize());
        const newStream = page.doc.context.flateStream(newContents);
        page.doc.context.assign(streamRef, newStream);
      }
    }
  });
};
// #endregion

// #region ##################################################################################### PROPS
type _Base = import("@utils/ClassTypes")._Base;
type _UserOrStudy = import("@utils/ClassTypes").AutoKeys<User | Study>;
// ModalCRUD => Rename all instances to use (CTRL + SHIFT + L)
export type ModalCRUDProps = {
  _controls?: boolean;
  _action: "create" | "update" | "delete";
  _origin?: _UserOrStudy;
  children?: React.JSX.Element[];
  _buttonClass?: string;
  _onClick?: () => void;
  _afterChange?: () => void;
  _cleanse?: boolean;
} & _Base &
  (
    | {
        _type: "files";
        _STAction: STPathProps;
        _originFile: string;
      }
    | {
        _type: "users" | "employees" | "studies";
        _FSAction: FSPathProps;
      }
  );
// #endregion

// #region ##################################################################################### COMPONENT
const _ModalCRUD = (props: ModalCRUDProps) => {
  // ---------------------------------------------------------------------- HOOKS
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(!(props._controls || props.children));
  const [LS, setLS] = useState<_UserOrStudy>({
    name: "",
    creationDate: new Date(),
    updateDate: new Date(),
    email: "",
    phone: "",
    isOrganization: false,
    ...props._origin,
  });
  const [LS2, setLS2] = useState({
    membretada: 0,
    files:
      props._type === "files" && props._originFile
        ? [props._originFile]
        : ([] as string[]),
  });
  const [LS3, setLS3] = useState({
    pass1: "",
    pass2: "",
  });
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const ref = useRef<null | HTMLInputElement>(null);
  const revalidator = useRevalidator();

  const isFile =
    props._type === "files"
      ? props._originFile !== parseFileData(props._originFile, "name", true)
      : false;
  const wantDelAdmin =
    props._action === "delete" &&
    props._type === "users" &&
    isAdmin(props._origin?.id || "nan");

  // ---------------------------------------------------------------------- FUNCTION HANDLERS
  const handleClose = () => {
    setOpen(false);
    props._onClick?.();
  };

  const handleOpen = useCallback(() => {
    if (props._cleanse) {
      setLS({
        name: "",
        creationDate: new Date(),
        updateDate: new Date(),
        email: "",
        phone: "",
        isOrganization: false,
        ...props._origin,
      });
      setLS2({
        membretada: 0,
        files:
          props._type === "files" && props._originFile
            ? [props._originFile]
            : ([] as string[]),
      });
      setLS3({
        pass1: "",
        pass2: "",
      });
      setSelectedFiles(null);
      setFileNames([]);
    }
    setOpen(true);
  }, [props]);

  const stopPropag = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };
  function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
    (e.target as HTMLDivElement).classList.add("drop");
    stopPropag(e);
  }
  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    stopPropag(e);
  }
  function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
    (e.target as HTMLDivElement).classList.remove("drop");
    stopPropag(e);
  }
  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    (e.target as HTMLDivElement).classList.remove("drop");
    const { files } = e.dataTransfer;

    if (!files.length) {
      GS.setAlert({
        _type: "informative",
        _message: "No se ha seleccionado ningún archivo.",
      });
    } else if (props._action === "update" && files.length > 1) {
      GS.setAlert({
        _type: "informative",
        _message: "Seleccione solo 1 archivo.",
      });
    } else selectFiles(files);

    stopPropag(e);
  }

  function handleAfterLoad(res: any) {
    setLoading("");

    if (res) {
      handleClose();
      GS.setAlert({
        _type: "success",
        _message: "Se han guardado los datos correctamente.",
      });

      // REFRESH DATA
      if (props._type === "files") {
        const ei = props._STAction.personID;
        const si = props._STAction.studyID;
        let temp: Study | undefined;
        const aux: string[] = [...GS.cache];
        if (ei)
          temp = GS.currentUser?.people
            ?.find((p) => p.id === ei)
            ?.studies?.find((s) => s.id === si);
        else temp = GS.currentUser?.studies?.find((s) => s.id === si);

        if (temp) {
          if (props._action === "create") temp.files.push(...aux);
          else temp.files = aux;
        }
      } else if (!GS.currentUser || props._type === "users") {
        if (GS.cache) GS.currentUser = GS.cache;
      } else if (props._type === "employees") {
        if (props._action === "create" || !GS.currentUser.people) {
          GS.currentUser.people = null;
        } else {
          const temp = GS.currentUser.people.findIndex(
            (e) => e.id === GS.cache.id
          );
          if (props._action === "update")
            GS.currentUser.people[temp] = GS.cache;
          if (props._action === "delete") GS.currentUser.people.splice(temp, 1);
        }
      } else {
        let thing: Person = GS.currentUser;
        if (
          props._FSAction !== "users" &&
          props._FSAction.subcoll === "studies" &&
          props._FSAction.personID &&
          GS.currentUser.people
        ) {
          const x = props._FSAction.personID;
          thing = GS.currentUser.people.find((e) => e.id === x) || thing;
        }

        if (props._action === "create" || !thing.studies) {
          thing.studies = null;
        } else {
          const temp = thing.studies.findIndex((e) => e.id === GS.cache.id);

          if (props._action === "update") thing.studies[temp] = GS.cache;
          if (props._action === "delete") thing.studies.splice(temp, 1);
        }
      }

      GS.cache = null;
      revalidator.revalidate();
      props._afterChange?.();
    }
  }

  // ---------------------------------------------------------------------- SELECT FILES
  function selectFiles(files: FileList | null) {
    // -------------------------------------------------- NO FILES
    if (!files) {
      setSelectedFiles(null);
      setFileNames([]);
      return;
    }

    // -------------------------------------------------- YES FILES
    const names: string[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const exIndex = file.name.lastIndexOf(".");

      names.push(file.name.substring(0, exIndex));
    }
    setSelectedFiles(files);
    setFileNames(names);
  }

  // ---------------------------------------------------------------------- HANDLE SUBMIT
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    GS.cache = null;
    const willUpdateEmail = props._origin?.email !== LS.email;
    const wantUpdateAdmin =
      props._type === "users" && isAdmin(props._origin?.id || "nan");

    // -------------------------------------------------- GATHER ELEMENTS
    const element = {
      users: new User({ ...LS }),
      employees: new Person({ ...LS }),
      studies: new Study({ ...LS }),
      files: null,
    };

    // -------------------------------------------------- PREVENT ADMIN MODIFICATIONS
    if (wantDelAdmin || (wantUpdateAdmin && willUpdateEmail)) {
      setLoading("");
      handleClose();
      GS.setAlert({
        _type: "warning",
        _message: "Este usuario está reservado como administrador.",
      });
      return;
    }

    // -------------------------------------------------- FSACTION
    setLoading("Cargando...");

    if (props._type === "files") {
      // CHECK
      if (
        (props._action === "create" && !selectedFiles && !LS2.files.length) ||
        (props._action === "update" && isFile && !selectedFiles)
      ) {
        setLoading("");
        GS.setAlert({
          _message:
            "Seleccione un archivo" +
            (props._action === "create" ? " o ingrese una URL" : ""),
          _type: "informative",
        });
        return;
      }

      // -------------------------------------------------- METHOD
      (async () => {
        const data: { fileName: string; url: string }[] = [];
        GS.cache = [];

        // REMOVE PREVIOUS
        if (props._action !== "create") {
          const newUrl = LS2.files[0] || props._originFile;
          if (!newUrl || !props._originFile) {
            setLoading("");
            GS.setAlert({
              _message: "Ingrese una URL válida",
              _type: "informative",
            });
            return null;
          }

          const newFiles: string[] = props._origin?.files
            ? [...props._origin.files]
            : [];
          const i = newFiles.indexOf(props._originFile);
          if (props._action === "update" && !isFile)
            newFiles.splice(i, 1, newUrl);
          else newFiles.splice(i, 1);
          GS.cache = [...newFiles];

          // UPDATE ARRAY
          let res = await FSAction(
            "update",
            {
              subcoll: "studies",
              personID: props._STAction.personID,
              userID: props._STAction.userID,
            },
            { id: props._STAction.studyID, files: newFiles }
          );
          if (!res) return res;

          if (isFile) {
            res = await STAction("delete", {
              ...props._STAction,
              fileName: parseFileData(props._originFile, "full", true),
            });
            if (!res) return res;
          }

          if (props._action === "delete" || !selectedFiles) return true;
        }

        // PREV ENCABEZADO
        // const bg_url =
        // "https://raw.githubusercontent.com/brian101016/topicos/4ebac866a95c3c045a408e335e1fd002f22636af/inventario/encabezados.pdf";
        const bg_url =
          "https://raw.githubusercontent.com/brian101016/topicos/decd9e1b357a1e6e871dee32bf7248debc87e4f3/inventario/encabezados.pdf";
        const bg_bytes = await fetch(bg_url).then((r) => r.arrayBuffer());
        const bg_pdf = await PDFDocument.load(bg_bytes);
        const bg_page = bg_pdf.getPages()[LS2.membretada === 2 ? 1 : 0];

        if (!selectedFiles) return data;

        // ============================== FOR SELECTED FILES
        for (let i = 0; i < selectedFiles.length; i++) {
          setLoading(`Cargando ${i + 1} de ${selectedFiles.length}...`);

          const fil = selectedFiles[i];
          let maybeFile: File | null = null;
          if (fil.type === "application/pdf" && LS2.membretada !== 0) {
            const fil_bytes = await fil.arrayBuffer();
            const fil_pdf = await PDFDocument.load(fil_bytes);
            const fil_pages = fil_pdf.getPages();

            const pdf_docu = await PDFDocument.create();
            const bg_embedded = await pdf_docu.embedPage(bg_page);

            // ============================== FOR FILE PAGES
            for (const fil_page of fil_pages) {
              removePageBackground(fil_page);
              const pdf_embedded = await pdf_docu.embedPage(fil_page);
              const pdf_page = pdf_docu.addPage([
                fil_page.getWidth(),
                fil_page.getHeight(),
              ]);

              const op_full = {
                x: 0,
                y: 0,
                height: pdf_page.getHeight(),
                width: pdf_page.getWidth(),
              };
              pdf_page.drawPage(bg_embedded, op_full);
              pdf_page.drawPage(pdf_embedded, op_full);
            }

            const pdf_bytes = await pdf_docu.save();
            maybeFile = new File([pdf_bytes.buffer], fil.name, {
              type: "application/pdf",
            });
          }

          const repl = parseFileData(props._originFile, "full", true);
          const res = await STAction(
            props._action === "create" ? "add" : "update",
            {
              ...props._STAction,
              fileName: repl || fileNames[i] || fil.name,
            },
            maybeFile || fil
          );

          if (res) data.push(...res);
        }

        return data;
      })().then((res) => {
        if (!res || res === true) return handleAfterLoad(res);
        setLoading("Finalizando...");
        const aux = [...res.map((v) => v.url)];
        if (props._action === "create") aux.push(...LS2.files);
        GS.cache.push(...aux);
        FSAction(
          "update",
          {
            subcoll: "studies",
            personID: props._STAction.personID,
            userID: props._STAction.userID,
          },
          {
            id: props._STAction.studyID,
            files: arrayUnion(...aux),
          }
        ).then(handleAfterLoad);
      });
    } else {
      // CREATE NEW USER
      if (props._type === "users" && props._action === "create") {
        if (LS3.pass1 !== LS3.pass2) {
          setLoading("");

          GS.setAlert({
            _type: "warning",
            _message: "Las contraseñas no coinciden",
          });

          return;
        }

        createUser(element.users.email, LS3.pass1).then((res) => {
          if (res) {
            FSAction("insert", "users", {
              ...element.users,
              id: res.user.uid,
            }).then(handleAfterLoad);
          } else handleAfterLoad(res);
        });
        return;
      }

      (async function () {
        // UPDATE USER PASSWORD
        if (
          props._type === "users" &&
          props._action === "update" &&
          willUpdateEmail &&
          LS.email &&
          LS.id
        ) {
          const url = "https://changeuseremail-lzm4syjeia-uc.a.run.app";
          const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify({
              pbuvAtxk: "KvFEmZZFpHasBv6nxSAwbhIZ6EO2",
              uid: LS.id,
              newEmail: LS.email,
            }),
          }).catch((e) => {
            GS.setAlert({
              _type: "error",
              _message:
                "Algo salió mal... revise su conexión e inténtelo de nuevo.",
            });
            console.error(e);
            return null;
          });

          if (!res?.ok) return setLoading("");
        }

        GS.cache = element[props._type];
        FSAction(
          props._action === "create" ? "add" : props._action,
          props._FSAction || "users",
          {
            ...(props._action !== "delete" ? element[props._type] : {}),
            id: props._action === "create" ? "" : props._origin?.id,
          }
        ).then(handleAfterLoad);
      })();
    }
  };

  // ---------------------------------------------------------------------- RETURN
  return (
    <>
      {/* -------------------------------------------------- OPEN BUTTON */}
      {(props.children || props._controls) && (
        <button
          className={props._buttonClass || "login modal-button"}
          onClick={handleOpen}
        >
          {props.children || "Abrir modal"}
        </button>
      )}

      {loading && <div className="spinner">{loading}</div>}

      {/* -------------------------------------------------- MODAL CONTAINER */}
      {open && (
        <div className={props.className + " modal-container"}>
          {/* -------------------------------------------------- MODAL */}
          <div className="modal">
            {/* ============================== TITLE */}
            <div className="modal-title">
              <h3>
                {TRANSLATE[props._action]} {TRANSLATE[props._type]}
              </h3>
              <button
                className="exit"
                onClick={handleClose}
                disabled={!!loading}
              >
                <img src={IP.icon.close} alt="close button" />
              </button>
            </div>

            {props._origin?.id && <h6>{props._origin.id}</h6>}
            {props._type === "files" && <h6>{props._STAction.studyID}</h6>}

            {/* ============================== INPUTS */}
            <form onSubmit={handleSubmit}>
              {props._type === "files" ? (
                <>
                  {/* ============================== PDF DROPZONE */}
                  {props._action === "create" ||
                  (props._action === "update" && isFile) ? (
                    <>
                      <label>Selecciona un archivo PDF</label>
                      <div
                        className={"dropzone"}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={() => ref.current?.click()}
                      >
                        {selectedFiles ? (
                          <>
                            <span className="bold">
                              {selectedFiles.length +
                                " archivo(s) seleccionado(s):"}
                            </span>
                            {(() => {
                              const arr: React.ReactNode[] = [];
                              let size = 0;
                              for (let i = 0; i < selectedFiles.length; i++) {
                                const file = selectedFiles[i];
                                size += file.size;
                                arr.push(
                                  <span key={i}>{fitString(file.name)}</span>
                                );
                              }
                              arr.push(
                                <span className="bold" key={arr.length}>
                                  {"Total: " + parseDataSize(size)}
                                </span>
                              );
                              return arr;
                            })()}
                          </>
                        ) : (
                          <>
                            <img src={IP.icon.download_cloud} alt="cloud" />
                            <span style={{ fontSize: "0.75rem" }}>
                              Arrastrar y soltar o examinar el archivo
                            </span>
                          </>
                        )}

                        <input
                          style={{ display: "none" }}
                          ref={ref}
                          onChange={(e) => selectFiles(e.target.files)}
                          type={"file"}
                          accept={
                            "image/*, .pdf, .doc, .docx, .xml, application/msword, " +
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          }
                          multiple={props._action === "create"}
                        />
                      </div>

                      <label>Renombrar archivos</label>
                      <ol className="filenames">
                        {fileNames.length
                          ? fileNames.map((name, i) => (
                              <li key={i}>
                                <Input
                                  _store={fileNames}
                                  _store_var={i + ""}
                                  _label={""}
                                  _placeholder={name}
                                  _width={"max"}
                                  _required={"*"}
                                  _charset={/[^\w ñáéíóúü';.(){}[\]\-+=]/i}
                                />
                              </li>
                            ))
                          : "Sin archivos..."}
                      </ol>

                      <Input
                        _store={LS2}
                        _store_var={"membretada"}
                        _label={"Hoja membretada (solo PDF)"}
                        _options={
                          new Map([
                            ["No", 0],
                            ["Sin fondo", 1],
                            ["Con fondo", 2],
                          ])
                        }
                        _required={""}
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        _disabled={props._action === "delete"}
                        _store={LS2.files}
                        _store_var={"0"}
                        _label={"URL directa"}
                        _placeholder={"https://example.com/ejemplo"}
                        _width={"max"}
                        _required={"*"}
                      />
                    </>
                  )}

                  {/* ============================== APPEND URL LIST */}
                  {props._action === "create" && (
                    <>
                      <CRUD
                        _store={LS2.files}
                        _store_new={""}
                        _title={"URLs directas"}
                        _create_button={"Agregar"}
                        _input_props={{
                          _placeholder: "https://example.com/ejemplo",
                        }}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Input
                    _disabled={props._action === "delete"}
                    _store={LS}
                    _store_var={"name"}
                    _label={"Nombre"}
                    _placeholder={"Ingrese un nombre"}
                    _preset={"name"}
                    _width={"max"}
                  />
                  {props._type === "studies" ? (
                    <Input
                      _disabled={props._action === "delete"}
                      _store={LS}
                      _store_var={
                        props._action === "create"
                          ? "creationDate"
                          : "updateDate"
                      }
                      _label={
                        props._action === "create"
                          ? "Fecha de creación"
                          : "Fecha de modificación"
                      }
                      _preset={"date"}
                    />
                  ) : (
                    <>
                      <Input
                        _disabled={props._action === "delete"}
                        _store={LS}
                        _store_var={"email"}
                        _label={
                          "Email" +
                          (props._type === "employees"
                            ? " del empleado (opcional)"
                            : "")
                        }
                        _placeholder={"Correo electónico"}
                        _preset={"email"}
                        _width={"max"}
                        _required={props._type === "employees" ? "" : "*"}
                      />
                      <Input
                        _disabled={props._action === "delete"}
                        _store={LS}
                        _store_var={"phone"}
                        _label={
                          "Teléfono" +
                          (props._type === "employees"
                            ? " del empleado (opcional)"
                            : " (opcional)")
                        }
                        _placeholder={"Número de teléfono"}
                        _preset={"tel"}
                        _width={"max"}
                        _required={""}
                      />

                      {props._type === "users" && (
                        <>
                          <div className="wrapper reversed">
                            <Input
                              _disabled={props._action === "delete"}
                              _store={LS}
                              _store_var={"isOrganization"}
                              _type={"checkbox"}
                              _label={"Es organización?"}
                              _required={""}
                            />
                          </div>

                          {props._action === "create" && (
                            <>
                              <Input
                                _store={LS3}
                                _store_var={"pass1"}
                                _label={"Nueva contraseña"}
                                _width={"max"}
                                _required={"*"}
                              />

                              <Input
                                _store={LS3}
                                _store_var={"pass2"}
                                _label={"Repetir contraseña"}
                                _width={"max"}
                                _required={"*"}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {/* ============================== MODAL CONTROLS */}
              <div className="modal-controls">
                <button
                  className="back"
                  onClick={handleClose}
                  disabled={!!loading}
                >
                  <img src={IP.icon.back} alt="previous" />
                  Regresar
                </button>
                <button className="login" disabled={!!loading || wantDelAdmin}>
                  {loading
                    ? "Enviando..."
                    : props._action === "create"
                    ? "Guardar nuevo"
                    : props._action === "delete"
                    ? "Eliminar"
                    : "Guardar cambios"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
// #endregion

// #region ##################################################################################### STYLES
const ModalCRUD = styled(_ModalCRUD).attrs(
  (props: ModalCRUDProps): ModalCRUDProps => {
    return { ...props };
  }
)<ModalCRUDProps>`
  ${(props) => css`
    .wrapper.reversed > label {
      font-size: 1rem;
    }

    ${parseCSS(props._style)}
  `}
`;
// #endregion

// #region ##################################################################################### EXPORTS
export default ModalCRUD;
// #endregion
